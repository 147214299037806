import React from 'react';


const ContactUs = () => {

    return (
        <div>
            <div id="contact-us" className="tabcontent">
                <div className="container">
                    <h3>Contact Us</h3>
                    <div className="contact-wrap">
                        <div className="contact-overlay"></div>
                        <div className="address">
                            <h5>Dr. Ramesh  </h5>
                            <p className="mb-2">General Secretary, Bangalore Orthopaedic Society</p>
                            <p className="mb-2">Address : No 71/A, 3rd Cross, Mount Joy Extension, Hanumanthanagar, Bangalore � 560 019</p>
                            <p className="mb-2">Email: generalsecretarybos@gmail.com</p>
                            <p className="mb-2">www. bangaloreorthopaedicsociety.com</p>
                            <p className="mb-2">Contact Number : 98455 74754</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default ContactUs;