import React from 'react'
import logo from '../images/logo_small_grey.png'
import president from '../images/Office-bearers-2023-2024/president.jpg'
import organising_secretary from '../images/Office-bearers-2023-2024/organising_secretary.png'

const Home = () => {
    return (
        <div id="landing-page" className="tabcontent">
            <div className="about-us container ">
                <h2 className="mx-auto mb-3 heading-color">Welcome to Bangalore Orthopaedic Society</h2>
                <p className="m-auto highlight-text">
                    <img src={logo} alt="logo" className="float-left mr-3" />
                    <span>The Bangalore Orthopaedic Society is a professional association for
                        Orthopaedic and Trauma Surgeons in Bangalore and for those who are outside Bangalore, but continue to show
                        interest in the affairs of the Association. Alongside its traditional focus on research and on promotion of the highest clinical and ethical standards</span></p>
                <h5 className="mx-auto mt-4 heading-color">The History of BOS</h5>
                <p className=" m-auto">
                    The History of Orthopaedics in Bangalore dates back to 1956, when Dr. H K Srinivas Murthy established the
                    Department of Orthopaedics in Victoria Hospital attached to Bangalore Medical College. Later by 1960-64,
                    others
                    joined as lecturers after post graduate qualification mostly from AIIMS, New Delhi and K.G. Medical
                    College
                    Lucknow. By 1980 a few private Orthopaedic surgeons opened their practices and were practicing Orthopaedic
                    surgery
                    in various hospitals, like St. Marthas, St. Philomina Hospital. <br></br><br></br>
                    Taking cue from clinical proceedings of Bombay Orthopaedic Society, few of them lead by Dr V Hanumanthaiah
                    met at
                    medical association lecture hall on a cup of tea and discussed the formation of Bangalore Orthopaedic
                    Society in
                    1986. The BOS was formed ultimately with Dr V Hanumanthaiah as the founder chairman. The society used to
                    meet once
                    every 2 months at various teaching hospitals and discuss difficult and rare cases on a dinner hosted by
                    pharmaceutical companies. A few years later the society was registered with registrar Government of
                    Karnataka and
                    full fledged office bearers were elected as Dr. V. H. Hanumanthaiah as President, Dr. N. Krishnappa, as
                    Secretary,
                    and 5 executive committee members. <br></br><br></br>
                    Bangalore Orthopaedic Society continued to meet though, a bit irregularly up till then and the meetings
                    were
                    changed to monthly and many of the colleagues in private colleges started attending these meetings, and a
                    minimal
                    fee of Rs. 30/- per year was fixed. With this fee it was possible to meet communication expenses to send
                    meeting
                    notices to various hospitals but the hospitality remained with pharmaceutical companies. Dr V
                    Hanumanthaiah
                    continued as president with Dr N Krishnappa as the secretary for 12 continuous years. Deliberations of the
                    monthly
                    meetings were well attended and many C.M.E.s were being held in addition to social service in the form of
                    Orthopaedic treatment for disabled persons and other Orthopaedic diseases. The highlight of their tenure
                    was
                    organizing the IOACON in 1994 at Bangalore, the success and standards of which are still talked about and
                    remains
                    as gold standard. The contribution of IOACON to BOS is the site at Koramangala. <br></br><br></br>
                    From the year 2000 the office bearers are elected every year during Annual BOS conferences.
                </p>
            </div>
            <div className="president-speak w-100 float-left mt-5">
                <div className="container">
                    <div className=" m-auto">
                        <div className="row speak-parent">
                            <div className="speak-inner">
                                <div className="image">
                                    <img src={president} alt="president" />
                                </div>
                                <div className="title heading-color">Dr Raju KP</div>
                                <div className="content">
                                President 
                                </div>
                                 <div className="title heading-color">President Speak</div> 
                                 <div className="content">
                                   Namaskara, 
                                My Respected Teachers, Esteemed Seniors and Dear Colleagues,
                                I, truly feel honoured and privileged to be the President of the Bangalore Orthopaedic Society and let me thank the esteem Members of the Bangalore Orthopaedic Society to have elected me to this August Organisation.
                                My dream is to make BOS an active and vibrant organisation with the motto "TOGETHER WE CAN" by 
                                " INSPIRE, INTEGRATE, INNOVATE". 

                                </div>
                                <div className="read-more"><a href="/PresidentSpeak" className="">Read More</a></div> 
                            </div>
                            <div className="speak-inner">
                                <div className="image">
                                    <img src={organising_secretary} alt="organising_secretary" />
                                </div>
                                <div className="title heading-color">Dr Basavaraj CM </div>
                                <div className="content">
                                Organising Secretary  
                                </div>
                                 <div className="title heading-color">Secretary Speak</div> 
                                 <div className="content">
                                     Namaskara,
                                    Respected Teachers, Friends and my Dear Colleagues,
                                    I truly feel honoured and privileged to be the Secretary of the Bangalore Orthopaedic Society and
                                    I sincerely thank each one of you for trusting me with this responsibility.
                                    As the pandemic restrictions end, it is an exciting new beginning for all of us as we have planned
                                    a wonderful year ahead, full of academics and clinical meetings in the physical format.
                                </div>
                                <div className="read-more"><a href="/Secspeak" className="">Read More</a></div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;
