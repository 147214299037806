import React from 'react';
import {NavLink, Link} from 'react-router-dom';
import { useLocation } from "react-router-dom";

const Headers = () => {

    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-dark bg-apikar">
                <div className="container px-0">
                    
                    <a className="navbar-brand w-50 logo" href="/">Bangalore Orthopaedic Society</a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar2"
                        aria-controls="collapsingNavbar2" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse w-50" id="collapsingNavbar2">
                        <ul className="navbar-nav header_links text-right menu-section">
                            <li className={splitLocation[1] === "" ? "active" : ""}>
                                <Link to='/'>Home</Link>
                            </li>
                            <li className={splitLocation[1] === "office-bearers" ? "active" : ""}>
                                <Link to='/office-bearers'>Office Bearers</Link>
                            </li>
                            <li className={splitLocation[1] === "members" ? "active" : ""}>
                                <Link to='/members'>Members</Link>
                            </li>
                            <li className={splitLocation[1] === "journals" ? "active" : ""}>
                                <Link to='/journals'>Journals</Link>
                            </li>
                            <li className={splitLocation[1] === "events" ? "active" : ""}>
                                <Link to='/events'>Events</Link>
                            </li>
                            <li className={splitLocation[1] === "links" ? "active" : ""}>
                                <Link to='/links'>Links</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}
export default Headers;