
var linkData = [
            {
                link: "http://www.aofoundation.org",
            },
            {
                link: "http://www.ioaindia.org",
            },
            {
                link: "http://www.orthoteers.org",
            },
            {
                link: "http://www.gosi.site90.com",
            },
            {
                link: "http://www.ejbjs.org",
            }
        ];

export default linkData
