import React from 'react'
import AnnouncementsData from "../data/AnnouncementsData.json";
import Parser from 'html-react-parser';

const Announcements = () => {
    return (
        <div>
            <div id="announcements" className="tabcontent">
                <div className="container">
                    <h3 className="heading-color">Events</h3>
                    {AnnouncementsData.map((data, index) => (
                        <div key={index}>
                            <h6><span className="fa fa-snowflake-o"></span>{data.title}</h6>
                            <p className="pl-4">{Parser(data.description)}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Announcements
