import React from 'react'
import { Route, Switch, Redirect } from "react-router-dom";
import Home from '../components/Home.js';
import PresidentSpeak from '../components/PresidentSpeak.js';
import Secspeak from '../components/Secspeak.js';
import Members from '../components/Members.js';
import OfficeBearers from '../components/OfficeBearers.js';
import Journals from '../components/Journals.js';
import Events from '../components/Events.js';
import Links from '../components/Links.js';
import AboutUs from '../components/AboutUs.js';
import ContactUs from '../components/ContactUs.js';

const BaseRouter = (props) => {
    return (
        <div>
            <Switch>
                <Route path="/" exact component={Home}/>
                <Route path="/office-bearers" exact component={OfficeBearers}/>
                <Route path="/members" exact component={Members}/>
                <Route path="/journals" exact component={Journals}/>
                <Route path="/events" exact component={Events}/>
                <Route path="/links" exact component={Links}/>
                <Route path="/about-us" exact component={AboutUs}/>
                <Route path="/contact-us" exact component={ContactUs}/>
                <Route path="/PresidentSpeak" exact component={PresidentSpeak}/>
                <Route path="/Secspeak" exact component={Secspeak}/>
            </Switch>

        </div>
    )
}

export default BaseRouter
