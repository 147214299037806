import React from 'react';


const AboutUs = () => {

    return (
        <div>
            <div className="about-info">
                <h4 className="about-heading heading-color">About Us</h4>
                <p>The Bangalore Orthopaedic Society is a professional association for Orthopaedic and Trauma Surgeons in
                    Bangalore and for those who are outside Bangalore, but continue to show interest in the affairs of the
                    Association. Alongside its traditional focus on research and on promotion of the highest clinical and
                    ethical standards, the Society and its members aim to increase understanding of orthopaedics and trauma,
                    not only among its members but also among the general public, who are the ultimate beneficiaries. The head
                    quarters of BOS is situated in Bangalore.</p>
            </div>
            <div className="about-links">
                <div className="container">
                    <div className="row">
                        <p><a className="abt-scroll" href="#abt-history">The History of BOS</a></p>
                        <p><a className="abt-scroll" href="#abt-aims">Aims and Objectives</a></p>
                        <p><a className="abt-scroll" href="#abt-membership">Membership</a></p>
                        <p><a className="abt-scroll" href="#abt-activities">Activities</a></p>
                        {/* <br /> */}
                    </div>
                </div>
            </div>
            <div id="abt-history">
                <h5 className="heading-color"><b>The History of BOS</b></h5>
                <p>
                    The History of Orthopaedics in Bangalore dates back to 1956, when Dr. H K Srinivas Murthy established the
                    Department of Orthopaedics in Victoria Hospital attached to Bangalore Medical College. Later by 1960-64,
                    others joined as lecturers after post graduate qualification mostly from AIIMS, New Delhi and K.G. Medical
                    College Lucknow. By 1980 a few private Orthopaedic surgeons opened their practices and were practicing
                    Orthopaedic surgery in various hospitals, like St. Marthas, St. Philomina Hospital.
                </p>
                
                <p>Taking cue from clinical proceedings of Bombay Orthopaedic Society, few of them lead by Dr V
                    Hanumanthaiah met at medical association lecture hall on a cup of tea and discussed the formation of
                    Bangalore Orthopaedic Society in 1986. The BOS was formed ultimately with Dr V Hanumanthaiah as the
                    founder chairman. The society used to meet once every 2 months at various teaching hospitals and discuss
                    difficult and rare cases on a dinner hosted by pharmaceutical companies. A few years later the society was
                    registered with registrar Government of Karnataka and full fledged office bearers were elected as Dr. V.
                    H. Hanumanthaiah as President, Dr. N. Krishnappa, as Secretary, and 5 executive committee members.</p>
                
                <p>Bangalore Orthopaedic Society continued to meet though, a bit irregularly up till then and the meetings
                    were changed to monthly and many of the colleagues in private colleges started attending these meetings,
                    and a minimal fee of Rs. 30/- per year was fixed. With this fee it was possible to meet communication
                    expenses to send meeting notices to various hospitals but the hospitality remained with pharmaceutical
                    companies. Dr V Hanumanthaiah continued as president with Dr N Krishnappa as the secretary for 12
                    continuous years. Deliberations of the monthly meetings were well attended and many
                    C.M.E.s were being held in addition to social service in the form of Orthopaedic treatment for disabled
                    persons and other Orthopaedic diseases. The highlight of their tenure was organizing the IOACON in 1994 at
                    Bangalore, the success and standards of which are still talked about and remains as gold standard. The
                    contribution of IOACON to BOS is the site at Koramangala. It has been proposed to build BOS Bhavan in this
                    site once the legal issues are settled.</p>
                <p>From the year 2000 the office bearers are elected every year during Annual BOS conferences.</p>
            </div>
            <div id="abt-aims">
                <h5 className="heading-color"><b>Aims and Objectives</b></h5>
                <ul>
                    <li>
                        <p>To promote, encourage and advance the development of the Science of Orthopaedics.</p>
                    </li>
                    <li>
                        <p>To promote, organize, encourage and support research in orthopaedics.</p>
                    </li>
                    <li>
                        <p>To promote, organize, encourage and support programme pertaining to the diagnosis and treatment of
                            handicapped.</p>
                    </li>
                    <li>
                        <p>To organize meetings, seminars, symposia, lectures, discourses and progrmmes for education, knowledge
                            and awareness of general public in respect of orthopaedic problems.</p>
                    </li>
                    <li>
                        <p>To establish research units in the field of the orthopaedics.</p>
                    </li>
                    <li>
                        <p>To establish meaningful inter-relationship between hospitals and / or institutions specializing in
                            orthopaedic surgery and / or research and the diverse teaching institutions.</p>
                    </li>
                    <li>
                        <p>To establish closer contact and co-ordination between hospitals dealing in orthopaedic surgery
                            concerned with the orthopaedic treatment.</p>
                    </li>
                    <li>
                        <p>To Print and /or publish and/or sell papers, documents, magazines, periodicals, journal, books,
                            brochures and other medical literature pertaining to orthopaedic surgery.</p>
                    </li>
                    <li>
                        <p>To advise and suggest training of medical and papra medical personnel required in orthopaedic
                            treatment and physically handicapped.</p>
                    </li>
                    <li>
                        <p>To form organization and / or institution constituting of qualified medical practitioners, scientists
                            and/or scientific workers directly and/or indirectly involved in the treatment of physically
                            handicapped.</p>
                    </li>
                    <li>
                        <p>Dividends and profits shall not be distributed among the members and same shall be utilized for
                            welfare of the Association.</p>
                    </li>
                </ul>
            </div>
            <div id="abt-membership">
                <h5 className="heading-color"><b>Membership</b></h5>
                
                <ul>
                    <li>
                        <p>Membership of the Association shall be open to all persons who have post graduate qualification in
                            Orthopaedic Surgery</p>
                    </li>
                    <li>
                        <p>In addition there shall be following classes of members, where ever in clause of member. The person
                            admitted shall have completed 18 yrs of age.</p>
                    </li>
                    <li>
                        <p>Life Member: Any person, qualified for full membership may become a life of the Association on
                            payment of Rs. 1000/- as fees.</p>
                    </li>
                    <li>
                        <p>Hon. Member: Any person who is interested in the welfare of the Association may be elected as an Hon.
                            Member by the General body.</p>
                    </li>
                    <li>
                        <p>Associate Member: A Person not belonging to any of the above categories, but belonging to medical or
                            Para Medical Profession may be admitted as an Associate Member. Post graduate students in Orthopaedics
                            may be admitted as Associate Members.</p>
                    </li>
                    <li>
                        <p>Application for admission to the full membership and Associate Membership of the Association shall be
                            made to the Executive committee in writing accompanied by the payment of subscription fees for such
                            membership. Executive committee shall accept such membership if deemed fit and get it approved by the
                            succeeding general body.</p>
                    </li>
                    <li>
                        <p>Candidates for all categories of Membership shall be sponsored by two full members of the
                            Association.</p>
                    </li>
                    <li>
                        <p>The executive committee shall have power and discretion to reject any application for membership on
                            such terms and conditions as the Execution Committee may deem fit and proper and get it approved by
                            the succeeding General body.</p>
                    </li>
                    <li>
                        <p>The General Body from time to time shall fix such Subscription or very it for the different
                            categories of the membership of the Association as the general body may deem fit and proper. All
                            annual subscriptions shall be payable in advance on the 1st day of January in every year, save and
                            except that the annual subscription of a person admitted to membership shall be payable along with his
                            application for membership and such payment shall always be for the entire year irrespective of the
                            month of admission.</p>
                    </li>
                    <li>
                        <p>A Members name may be deleted from the list of members;</p>
                    </li>
                    <p>a. On his/her submission of resignation in writing to the Association.</p>
                    <p>b. On his/her death</p>
                    <p>c. On his/her failure to pay the annual subscription for more than 2 yrs from the date it becomes due.
                    </p>
                    <p>d. On his/her membership being cancelled by a resolution passed by the General body.</p>
                    <p>e. On him/her being expelled from the Association by the general body on any other ground deemed fit.
                    </p>
                    <li>
                        <p>A member expelled under the aforesaid rules may be reinstated by a resolution passed at a meeting of
                            the general body at which atleast @ of the members present at such a meeting shall vote for
                            reinstation.</p>
                    </li>
                    <li>
                        <p>Any person ceasing to be member of the association, for any reasons shall nevertheless remain liable
                            to pay all annual subscription and other sums payable by him at the date of his ceasing to be a
                            member.</p>
                    </li>
                    <li>
                        <p>The association shall cause to be kept a register in which shall be entered.</p>
                    </li>
                    <p>a. Name of each member.</p>
                    <p>b. The address given in the application and</p>
                    <p>c. The className of Membership in which the enrollment is effected.</p>
                    <li>
                        <p>Every member shall forthwith notify to the secretary any change of address. The address registered
                            shall be the registered address of the member and shall be the address to which all notices intended
                            for such a member shall
                            be sent unless such a notice is published in newspapers in accordance with the provisions of these
                            rules.</p>
                    </li>
                </ul>
            </div>
            <div id="abt-activities">
                <h5 className="heading-color"><b>Activities</b></h5>
                <br/>
                <h6 className="heading-color"><b>BOSCON</b></h6>
                <p>The BOS holds an annual scientific meeting to advance knowledge and practice in the field of orthopaedic
                    and trauma surgery. It jointly organizes other national and international conferences</p>
                
                <h6 className="heading-color"><b>COE</b></h6>
                
                <p>As a part of continuing orthopaedic education (COE) the BOS conducts regular monthly meetings on the last
                    Sunday of every month. The BOS also conducts regular Courses/Symposia/Hands on workshops etc in various
                    subspecialties in Orthopaedics. (Please refer to calendar of events for details)</p>
                
                <h6 className="heading-color"><b>Establish Committees</b></h6>
                
                <p>To advance the aims of the Association and to respond to issues raised by members.</p>
                
                <p className="heading-color"><b>Freshly proposed activities </b>(from the year 2008)</p>
                
                <p>To advance the aims of the Association and to respond to issues raised by members. </p>
                
                <p className="heading-color"><b>Freshly proposed activities </b>(from the year 2008)</p>
                <br/>
                <p className="heading-color"><b>Multi Centric Trials</b></p>
                
                <p>To organize/conduct meaningful and relevant multi centric trials of highest clinical and ethical
                    standards.</p>
                <h6 className="heading-color"><b>Joint Registry</b></h6>
                
                <p>To maintain one common registry of all the joint replacements done by the members.</p>
                
                <h6 className="heading-color"><b>Musculo Skeletal Tumor registry</b></h6>
               
                <p>To maintain one common registry of all the Musculo skeletal tumors reported/treated by the members.</p>
                
            </div>
            <br />
        </div>
    );

}


export default AboutUs;