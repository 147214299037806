import React, {useState, useEffect} from 'react'
import AnnouncementsData from "../data/AnnouncementsData.json";
import Announcements from './Announcements';
import NoEvents from './NoEvents';
import File6 from '../files/Confren.pdf'
import File7 from '../files/Confren_page1.jpg'
import File15 from '../files/BOS_event_may_2023.pdf'
import File16 from '../files/CME.pdf'
import File17 from '../files/BOS_2023.pdf'
import File18 from '../files/DRBAS_JULY.pdf'
import File19 from '../files/ARTHRALSIA.pdf'
import File20 from '../files/BOS-AUGUST_2023.JPG'
import File21 from '../files/BOS_Monthly_Meet_OCT_2023.pdf'
import File22 from '../files/BOSC0N_2024.pdf'
import File23 from '../files/BOS_Election_2024-Code_of_Conduct_Declaration.pdf'
import File24 from '../files/BOS_Nomination_form.pdf'
import File25 from '../files/BOSElection_2024_Notification.pdf'
import File26 from '../files/NEW_SCIENTFIC.pdf'
import File27 from '../files/drarunmallaji.JPG'
import File28 from '../files/drhschandrashekar.JPG'
import File29 from '../files/BANGALORE_ORTHOPAEDIC_SOCIETY_4.pdf'


const Events = () => {
    console.log(AnnouncementsData);
    const [announcement, setannouncement] = useState('');

    useEffect(() => {
          AnnouncementsData.length > 0 ? setannouncement(<Announcements/>) : setannouncement(<NoEvents/>);
    }, []);

    return (
        <div>
        <h2 align="center">Events</h2>
         <p>&nbsp;</p>
         <h3 align="left">2024</h3><br></br>
         <p>1. 16th & 17th March - BOSCON 2024 <a href={File26} target="_blank"> <span className="fa fa-download"></span> Click here for more Information</a></p>
         <p>2. Oration -"Technology (CAOS/Robotics) Necessity or industry driven?" By PROF. DR ARUN MULLAJI <a href={File27} target="_blank"> <span className="fa fa-download"></span> Click here for more Information</a></p>
         <p>3. Oration -"MY JOURNEY IN ORTHOPEDICS" By PROF. DR H.S.CHANDRASHEKAR <a href={File28} target="_blank"> <span className="fa fa-download"></span> Click here for more Information</a></p>
         <p>4. Young Surgeons Forum<a href={File29} target="_blank"> <span className="fa fa-download"></span> Click here for more Information</a></p>
         <h3 align="left">BOS Election 2024 16th March</h3>
         <h3 align="left">BOS Election 2024 16th March</h3>
         <p>1. BOSElection 2024 - Notification <a href={File25} target="_blank"> <span className="fa fa-download"></span> Click here for more Information</a></p>
         <p>2. BOS Nomination form <a href={File24} target="_blank"> <span className="fa fa-download"></span> Click here for more Information</a></p>
         <p>3. BOS Election 2024 Code of Conduct Declaration <a href={File23} target="_blank"> <span className="fa fa-download"></span> Click here for more Information</a></p>
         <h3 align="left">2023</h3>
         <p>1. 28th May 2023 - BOS Monthly Meet <a href={File15} target="_blank"> <span className="fa fa-download"></span> Click here for more Information</a></p>
         <p>2. 17th June 2023 - CME Programme <a href={File16} target="_blank"> <span className="fa fa-download"></span> Click here for more Information</a></p>
         <p>3. 25th June 2023 - BOS Monthly Meet <a href={File17} target="_blank"> <span className="fa fa-download"></span> Click here for more Information</a></p>
         <p>4. 15th July 2023 - BOS Monthly Meet <a href={File18} target="_blank"> <span className="fa fa-download"></span> Click here for more Information</a></p>
         <p>5. ARTHRALSIA - 24th July<br></br>Honourable Respected BOS members, 

<p>This time we have   International speaker program (ISP) under the Aegis of Bangalore Orthopedic Society on “Emerging Trends in the Management of Osteoarthritis” ARTHALSIA</p> 
 
<p>Venue: Taj , MG Road, Bangalore. Time: 7pm to 10pm.<br></br>
24-07-2023 Monday.<br></br>
Your presence makes the meeting meaning full and successful.<br></br>
Warm regards. <br></br>
Dr Raju KP and Team</p>

<p>Respected BOS and KOA Members ,<br></br>
KINDLY BOOK YOUR SEAT FOR BOS WINTER 🥶 MEET IN KYRGYZTAN - BISHKEK FROM SEP 21 TO SEPTEMBER 25 .<br></br>
 we have to finalise .  My Humble request.  “In Life, It’s Not Where You Go, It’s Who You Travel With.” Life is short. </p> 

<p>Warm regards<br></br>
Team BOS</p><a href={File19} target="_blank"> <span className="fa fa-download"></span> Click here for more Information</a></p>
<p>6. 26th August 2023 - BOS Monthly Meet <a href={File20} target="_blank"> <span className="fa fa-download"></span> Click here for more Information</a></p>
<p>Honourable Respected BOS members,<br></br>VENUE CHANGE<br></br>TODAY EVENING we have BOS Monthly Meeting in &nbsp;Hotel CHANCERY PAVILION , Residency Road, Bengaluru on Saturday 26th August 2023 under Manipal Hospital Old Airport road (musculoskeletal oncology).<br></br>Kindly please attend &nbsp;starts at 6.30 onwards till 9 30 PM followed by Fellowship and Dinner .<br></br>Warm regards.<br></br>Dr Raju KP and Team<br></br><br></br>Respected BOS and KOA Members ,<br></br>KINDLY BOOK YOUR SEAT FOR BOS WINTER 🥶 MEET IN KYRGYZTAN - BISHKEK FROM SEP 21 TO SEPTEMBER 25 . &nbsp;<br></br>Counter will be opened by 15th we have to finalise . &nbsp;My Humble request. &nbsp;&ldquo;In Life, It&rsquo;s Not Where You Go, It&rsquo;s Who You Travel With.&rdquo; Life is short. &nbsp;<br></br><br></br>Warm regards &nbsp;<br></br>Team BOS</p>
<p>7. 29th October 2023 - BOS Monthly Meet <a href={File21} target="_blank"> <span className="fa fa-download"></span> Click here for more Information</a></p>
        </div>
    )
}

export default Events