import React from 'react'
import rss from '../images/rss.png'
import linkData from '../data/LinkData'

const Links = () => {
    return (
        <div className="container">
            <div className="w-100 float-left">
                <div className="row">
                    <div className="form-group col-md-8">
                        <h3 className="heading-color">Sample Data-Weblinks</h3>
                        {/* <label className="float-left mr-3 mt-4" htmlFor="exampleFormControlSelect2">Display #</label>
                        <select className="form-control w-50 mt-4 " id="exampleFormControlSelect2">
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                        </select> */}
                        <table className="table table-striped table-bordered mt-4">
                            <thead>
                                <tr>
                                    <th>URL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {linkData.map((links, index) => (
                                    <tr data-index={index} key={index}>
                                        <td><a
                                            href={links.link} target="_blank">{links.link}</a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-4">
                        <h5 className="mb-2">Syndicate Feeds</h5>
                        <hr className="mt-3" />
                        <div className="syndicate">
                            <a href="/index.php/journal-publications?format=feed&amp;type=rss">
                                <img className="float-left mr-2" style={{ width: '15px' }} src={rss} alt="Rss" />
                                Feed Entries
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Links
