import React from 'react'
import president from '../images/Office-bearers-2023-2024/president.jpg'

const PresidentSpeak = () => {
    return (
    <div>
                    <h1 align="center" >President Speak</h1>
                    <div class="speak-inner1">
                   <div className="image">
                                    <img align="right" src={president} alt="president" />
                                </div>
                <p><b>Namaskara,</b></p>
                <p>My Respected Teachers, Esteemed Seniors and Dear Colleagues,</p>
                <p>I, truly feel honoured and privileged to be the President of the Bangalore Orthopaedic Society and let me thank the esteem Members of the Bangalore Orthopaedic Society to have elected me to this August Organisation.</p>
                <p>My dream is to make BOS an active and vibrant organisation with the motto "TOGETHER WE CAN" by</p>
                <p><b>" INSPIRE, INTEGRATE, INNOVATE".</b></p>
                <p>&nbsp;</p>
                <p>My emphasis will be on the Basic training of Postgraduates as well as 'Young and budding' Orthopaedic Surgeons, thus providing them with an extra tool in their armamentarium and empowering them with skill-based knowledge to tackle through discussion with an unwavering commitment to Academics and Skill improvement of orthopods by highly experienced surgeons.</p>
                <p>We have planned a wonderful year, full of monthly academics, CME and clinical meetings in the physical format and hands-on workshops. We will also have regular teaching programs for all Postgraduates.</p>
                <p>BOS will be organizing various social events and&nbsp; Medical Camps throughout the year to create awareness among people and simultaneously free treatment for the needy and highlight the Doctors' contribution to society.</p>
                <p>I, consider myself to be extremely fortunate to have a team of dynamic and hardworking members in the Executive Committee and Working Committee, and I am sure that we together under the guidance of our Indian Orthopaedic Association team, Karnataka Orthopaedic Association team and our esteemed past Presidents will steer our organisation to new heights.</p>
                <p>With the blessing of IOA, KOA, and BOS Members 'Together we can Achieve '.</p>
                <p>&nbsp;</p>
                <p>With warm Regards</p>
                <p><strong>Prof. Dr. Raju K P</strong></p>
                <p>President,</p>
                <p>Bangalore Orthopaedic Society.</p>
                <p>Chairman for IOA Membership drive and Executive committee member,</p>
                <p>Indian Orthopaedic Association</p> 
                </div></div>
  
                     
    );
}

export default PresidentSpeak;
