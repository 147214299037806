import React from 'react'
import AnnouncementsData from "../data/AnnouncementsData.json";
import File1 from '../files/Bylaws.pdf'
import File2 from '../files/Amended-bylaws.pdf'
import File3 from '../files/bos_application_form.pdf'
import File4 from '../files/Disabilty_Assessment_Protocol.pdf'
const LeftSection = () => {

  const renderAnnouncements = () => {

    return AnnouncementsData.map((Announcement, index) => {
      const {title} =  Announcement;
      return (
        
        <span key={index} className="scroll-title"><a href="/events" style={{textDecoration:'none'}}><span className="fa fa-angle-double-right"></span> {title}</a></span>
      )
    });
  }
  
  return (
    <div className="left-side-menu">
      <ul>
        <h5>Main Menu </h5>
        <li><a href="/"><span className="fa fa-chevron-right"></span>Home</a></li>
        <li><a href="/about-us"><span className="fa fa-chevron-right"></span>About Us</a></li>
        <li><a href="/contact-us"><span className="fa fa-chevron-right"></span>Contact Us</a></li>
      </ul>
      <ul>
        <h5>Downloads</h5>
        <li><a href={File2} target="_blank"> <span className="fa fa-download"></span> Ammended BOS Bye-laws</a></li>
        <li><a href={File3} target="_blank"> <span className="fa fa-download"></span> Membership Form</a></li>
        <li><a href={File1} target="_blank"> <span className="fa fa-download"></span> BOS BYLAWS</a></li>
        <li><a href={File4} target="_blank"> <span className="fa fa-download"></span> Disabilty Assessment Protocol</a></li>
      </ul>
      <ul className="announcements">
        <h5>Announcements</h5>
        <marquee behavior="scroll" direction="up" scrollamount="1" >
          <p className="mb-0 ">
            
            {renderAnnouncements()}
          </p>
        </marquee>

      </ul>
    </div>
  )
}
export default LeftSection;