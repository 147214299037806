import React from 'react'
import president from '../images/Office-bearers-2024-2025/president.png'
import organising_secretary from '../images/Office-bearers-2024-2025/organising_secretary.png'
import general_secretary from '../images/Office-bearers-2024-2025/general_secretary.png'
import treasurer from '../images/Office-bearers-2024-2025/treasurer.png'
import presidentelect from '../images/Office-bearers-2024-2025/presidentelect.png'
import e1 from '../images/Office-bearers-2024-2025/e1.png'
import e2 from '../images/Office-bearers-2024-2025/e2.png'
import e3 from '../images/Office-bearers-2024-2025/e3.png'
import e4 from '../images/Office-bearers-2024-2025/e4.png'
import e5 from '../images/Office-bearers-2024-2025/e5.png'
import e6 from '../images/Office-bearers-2024-2025/e6.png'
import e7 from '../images/Office-bearers-2024-2025/e7.png'
import s1 from '../images/Office-bearers-2024-2025/past_gen_sec.png'
import s2 from '../images/Office-bearers-2024-2025/IMMEDIATE_PAST_PRESIDENT.png'

const imgDesign = {
  width: '22.5%',
  margin: '10px',
  padding: '10px',
  border: '1px solid transparent !important',
  boxShadow: '0px 0px 5px rgb(0 0 0 / 10%)'
}

function OfficeBearers() {
  return (
    <div>
      <div className="container office_bearers">
        <h3 className="heading-color">Office Bearers 2024 - 2025</h3>
        <div className="row ">
          <div className="card text-center" >
            <img src={president} />
            <div className="card-body p-0">
              <p className="card-title my-3">Dr Avinash Parthasarathy <br />President</p>
            </div>
          </div>
          <div className="card text-center" >
            <img src={organising_secretary} />
            <div className="card-body p-0">
              <p className="card-title my-3">Dr Shekar Mudramaiah  <br />Organising Secretary</p>
            </div>
          </div>
          <div className="card mr-0 text-center" >
            <img src={general_secretary} />
            <div className="card-body p-0">
              <p className="card-title my-3">Dr Ramesh B <br />  General Secretary</p>
            </div>
          </div>
            <div className="card text-center" >
            <img src={treasurer} />
            <div className="card-body p-0">
              <p className="card-title my-3">Dr Chidanand KJC  <br /> Treasurer</p>
            </div>
          </div>
          <div className="card text-center" >
            <img src={presidentelect} />
            <div className="card-body p-0">
              <p className="card-title my-3">Dr. Subodh Shetty  <br /> President Elect</p>
            </div>
          </div>
          <div className="card text-center" >
            <img src={e1} />
            <div className="card-body p-0">
              <p className="card-title my-3">Dr. Rudraprasad <br />EC Member</p>
            </div>
          </div>
          <div className="card text-center" >
            <img src={e2} />
            <div className="card-body p-0">
              <p className="card-title my-3">Dr. Lokesh H <br />EC Member</p>
            </div>
          </div>
          <div className="card text-center" >
            <img src={e3} />
            <div className="card-body p-0">
              <p className="card-title my-3">Dr. Venu Madhav <br />EC Member</p>
            </div>
          </div>
          <div className="card mr-0 text-center" >
            <img src={e4} />
            <div className="card-body p-0">
              <p className="card-title my-3">Dr. Manjunatha Reddy  <br />EC Member</p>
            </div>
          </div>
          <div className="card text-center" >
            <img src={e5} />
            <div className="card-body p-0">
              <p className="card-title my-3">Dr. Saraswati V <br />EC Member</p>
            </div>
          </div>
          <div className="card text-center" >
            <img src={e6} />
            <div className="card-body p-0">
              <p className="card-title my-3">Dr. Shashank G Reddy <br />EC Member</p>
            </div>
          </div>
          <div className="card text-center" >
            <img src={e7} />
            <div className="card-body p-0">
              <p className="card-title my-3">Dr. Suman MB <br />EC Member</p>
            </div>
          </div>
          <div className="card text-center" >
            <img src={s2} />
            <div className="card-body p-0">
              <p className="card-title my-3">Dr Raju K P <br />Immediate Past President</p>
            </div>
          </div>
        <div className="card text-center" >
            <img src={s1} />
            <div className="card-body p-0">
              <p className="card-title my-3">Dr Srinivas Reddy <br />Past General Secretary</p>
            </div>
          </div>
         </div>
        <div className="old-info-section w-100 float-left mt-3">
          <ul className="nav nav-tabs nav-fill" role="tablist">
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#presidents" role="tab" aria-controls="presidents"
                aria-selected="true">Past Presidents</a>
            </li>
            <li className="nav-item">
              <a className="nav-link " data-toggle="tab" href="#secretaries" role="tab" aria-controls="secretaries"
                aria-selected="false">Past Secretaries</a>
            </li>
          </ul>
          <div className="tab-content w-100 float-left mt-4">
            <div className="tab-pane" id="presidents" role="tabpanel" aria-labelledby="presidents-tab">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th style={{ width: '20%', textAlign: 'center' }}>SL NO</th>
                    <th>Names</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ textAlign: 'center' }}>1</td>
                    <td>Dr. V H Hanumanthiah</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>2</td>
                    <td>Dr. Sridhar Murthy J.N</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>3</td>
                    <td>Dr. Rajiv Naik</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>4</td>
                    <td>Dr. Rajagopal</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>5</td>
                    <td>Dr. Naresh Shetty</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>6</td>
                    <td>Dr. Thomas Chandy</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>7</td>
                    <td>Dr. Partha Sarathy</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>8</td>
                    <td>Dr. VijayKumar</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>9</td>
                    <td>Dr. Ram lyer</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>10</td>
                    <td>Dr. Sharan Patil</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>11</td>
                    <td>Dr. Muralidhar</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>12</td>
                    <td>Dr. Uday Kumar</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>13</td>
                    <td>Dr. Purushotham  V.J</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>14</td>
                    <td>Dr. Ravindra</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>15</td>
                    <td>Dr. Deepak S</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>16</td>
                    <td>Dr. Anand Galagali</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>17</td>
                    <td>Dr. Shivanand</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>18</td>
                    <td>Dr. Ramesh Krishna</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>19</td>
                    <td>Dr. Bharath Raju</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>20</td>
                    <td>Dr. Harshad M Shah</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>21</td>
                    <td>Dr Manoj Kumar</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>22</td>
                    <td>Dr. Vanamali B Seetharam</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>23</td>
                    <td>Dr Raju K P</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="tab-pane" id="secretaries" role="tabpane1" aria-labelledby="presidents-tab">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th style={{ textAlign: 'center', width: '20%' }}>SL NO</th>
                    <th>Names</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ textAlign: 'center' }}>1</td>
                    <td>Dr. Nagaraj</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>2</td>
                    <td>Dr. Vasudev Prabhu</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>3</td>
                    <td>Dr. Naveen Kumar N</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'center' }}>4</td>
                    <td>Dr. Srinivas Reddy </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
     </div>
      
  )
}

export default OfficeBearers
