// JavaScript source code
import React from 'react'
import organising_secretary from '../images/Office-bearers-2023-2024/organising_secretary.png'

const Secspeak = () => {
    return (
    <div>
                    <h1 align="center" >Secretary speak </h1>
                    <div class="speak-inner1">
                   <div className="image">
                                    <img width="215px" height="215px" align="right" src={organising_secretary} alt="organising_secretary" />
                                </div>
                <p><b>Namaskara,</b></p>
                <p>Respected Teachers, Friends and my Dear Colleagues,</p>
                <p>I truly feel honoured and privileged to be the Secretary of the Bangalore Orthopaedic Society and
I sincerely thank each one of you for trusting me with this responsibility.</p>
                <p>As the pandemic restrictions end, it is an exciting new beginning for all of us as we have planned
a wonderful year ahead, full of academics and clinical meetings in the physical format.</p>
                <p>&nbsp;</p>
                <p>We will have more courses with cadaveric and hands-on workshops.
We will be focusing more on PG teaching programs, basic fracture fixation and saw bone
workshops.</p>
                <p>Every event of BOS this year will aim to reflect <b>'Inspire, Integrate and Innovate'</b>, together we can
theme set by our President Dr. Raju KP.</p>
                <p>I look forward to your support in working together as a team, achieving academic excellence and
taking BOS to new heights.</p>
<p>&nbsp;</p>
                <p>Yours sincerely,</p>
                <p><strong>Dr.Basavaraj C M</strong></p>
                <p>Secretary,</p>
                <p>Bangalore Orthopaedic Society.</p>
                </div></div>
  
                     
    );
}

export default Secspeak;
