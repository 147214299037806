import React, {useState, useEffect} from 'react'
import JsonData from "../data/MembersData.json";
import ReactPaginate from 'react-paginate';
import File5 from '../files/MEMBERLIST2024.pdf';

function Members() {

  const [members, setMembers] = useState(JsonData.slice()); 

  const [pageNumber, setPageNumber] = useState(0);

  const membersPerPage = 15;

  const pagesVisited = pageNumber * membersPerPage;

  const displayMembers = members.slice(pagesVisited, pagesVisited + membersPerPage).map((member, index) => {
    return (
            <tr data-index={index} key={index}>
              <td>{member.id}</td>
              <td>{member.name}</td>
            </tr>
    );
  });

  useEffect(() => {

  }, []);

  const pageCount = Math.ceil(members.length / membersPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  
  return (
    <div className="container">
      <div class="row">
          <div className="col-lg-6">
            <h3 className="heading-color">Members List</h3>
          </div>
          <div className="col-lg-6 text-right">
            <a href={File5} target="_blank"><span className="fa fa-download"></span> Print</a>
          </div>      
      </div>
      <div className="members-table">
        <table className="table table-striped table-bordered mt-4">
          <thead>
            <tr><th style={{ width: '40%' }}>LIFE MEMBERSHIP NUMBER </th><th>Names</th></tr>
          </thead>
          <tbody>
            {displayMembers}
          </tbody>        
        </table>
      </div>
      <div className="pagination-sec">
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </div>
    </div>
  )
}

export default Members