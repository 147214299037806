import './App.css';
import React, { Component } from 'react';  
import LeftSection from './components/LeftSection';
import Headers from './components/Headers';
import Footer from './components/Footer';
import banner from './images/bannerbos2023-2024.png'
import {
  BrowserRouter as Router,
} from "react-router-dom";
import BaseRouter from "./config/BaseRouter";




function App() {
  return (
    <Router>
      <div className="App">
        <div>
          <Headers />
          <img src={banner} alt="Bangalore Orthopaedic Society" width="100%" height="auto" ></img>
        </div>
        <div className="container"  style={{clear: "both"}}>
          <div className="row">
            <div className="col-lg-3">
              <LeftSection />
            </div>
            <div className="col-lg-9">
              <BaseRouter />
            </div>
          </div>
        </div>
      <Footer/>
      </div >
    </Router>

  );
}

export default App;
