import React from 'react'

const Footer = () => {
    return (
        <div className="w-100 float-left mt-5 footer py-2">
            <div className="container text-center text-white">
                <br/>
                    <p className="m-0">Copyright © 2021 Bangalore Orthopaedic Society. All Rights Reserved.</p>
        </div>
            </div>
            )
}

            export default Footer
